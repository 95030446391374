import { Pipe, PipeTransform } from '@angular/core';

import { TAddress, TAddressWithoutCoordinates } from '@models/mapTypes';

@Pipe({
  name: 'addressString',
  standalone: true,
})
export class AddressStringPipe implements PipeTransform {
  transform(address: TAddress): string {
    const tempAddress: TAddressWithoutCoordinates = {
      region: address.region,
      area: address.area,
      city: address.city,
      settlement: address.settlement,
      street: address.street,
      house_block_flat: address.house_block_flat,
    };

    return Object.values(tempAddress).filter(Boolean).join(', ');
  }
}
